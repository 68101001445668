import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Whether you\'re traveling the world or your neighborhood, here are a two technology tips to make the most of any trip.",
  {
    format: "section",
    content: "Offline maps"
  },
  "Your phone\'s GPS works just fine without Wi-Fi or cellular service ... if you have the maps downloaded before you lose signal.",
  "Not only will downloading maps when you have Wi-Fi help to save cellular data, but this can also help to avoid stressful situations when cellular is just too weak to cut it both at home and abroad.",
  "To get a lay of the land on iOS, tap your profile picture, then click on \"Offline Maps\" and \"Download New Map.\" Google Maps makes this easy, as well, by tapping your profile picture, then going to \"Offline Maps\" and \"Select Your Own Map.\"",
  "From there in both apps, put whatever areas you\'ll be visiting in the highlighted rectangle and click \"Download.\" You can download as many maps as you want, so I recommend downloading a map for anywhere you are planning on going if you have the space. A typical map will use around 200 MB per map for a greater-Yakima-size area, up to a gigabyte if you push things to the limit.",
  "Now you can explore with confidence, whether there is signal or not.",
  {
    format: "section",
    content: "Wi-Fi calling"
  },
  "Wi-Fi calling is another feature I recommend using whether you are home or abroad. It allows your phone\'s calls and texts to be sent over a Wi-Fi connection, in addition to the cellular network. Once enabled, your phone will automatically use whichever connection is strongest for your calls and texts, which you can continue to dial and send as usual.",
  "If you\'re abroad and use Wi-Fi calling, most carriers will bill you at your standard domestic rate (instead of international rates) for calls and texts back to the U.S. Back home, Wi-Fi calling is great for anywhere the cell service is just a little too weak – whether it\'s that one room in your house or at a cabin deep in the countryside.",
  "To enable Wi-Fi calling on iOS, open the Settings app, then go to \"Cellular\" and \"Wi-Fi Calling.\" On Android-based devices, Wi-Fi calling settings are usually available in either the system-wide Settings app or in the settings section of the Phone app, although the exact location varies by manufacturer. You can generally tell if your phone is using Wi-Fi for calls and texts if your carrier name shows \"Wi-Fi\" after it in the status area.",
  "Whether surfing the waves while on a trip or surfing the internet, here\'s to staying connected.",
]