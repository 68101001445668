import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import thelanes from "./the-lanes.jpg";
import sisters from "./the-seven-sisters.jpg";
import clipping from "../resources/sep-21-24.pdf";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "graduate"
}

const article: Article = {
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/technology-tips-offline-maps-and-wi-fi-calling/article_e54ca192-733a-11ef-abab-d7934a04dfa3.html",
  date: new Date(2024, 8, 21),
  articleType: "column",
  author: [ ME ],
  title: "Technology Tips: Offline maps and Wi-Fi calling",
  images: [
    {
      caption: "Whether you are wandering through exotic sights, like The Lanes in Brighton, England, or navigating through the countryside back home, downloading maps for offline use means that losing service doesn't mean losing your way.",
      credit: ME,
      src: thelanes
    },
    {
      caption: "Known as the Seven Sisters, these white chalk cliffs tower over the English coastline. Offline maps and Wi-Fi calling can help you stay connected in remote places like this.",
      credit: ME,
      src: sisters 
    }
  ],
  clipping,
  content
};
export default article;