import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  // It is probably for the best that my line "Most people do not think too much about the cables they use in their day-to-day lives for their devices. As such, most people will go on to live happy and fulfilling lives." was truncated.
  "Most people do not think too much about the cables they use in their day-to-day lives for their devices. However, I am not automatically content with the cables I have nor by the tangled mess of blocky wall adapters that work with one device only.",
  "Instead, I found my answer in the USB-C.",
  "One of the appeals of the USB-C connector is that it is reversible, unlike many other connectors. If you have ever tried to plug in a device, only to realize that the connector is the wrong way around, I know your pain. The oval shape of the USB Type C makes it even easier to align with your device. Plus, the barbed retention tabs in the connector itself mean that your devices will stay connected, even if jostled.",
  "Another compelling USB-C feature is its support for USB Power Delivery. USB-PD is a fast-charging technology that negotiates the power needs between the connected device and the charger, dynamically adjusting the voltage and current to deliver the optimal amount of power.",
  "If your charger is one of many that supports USB-PD, that same USB-C cable can charge a small device like a smartphone, then seamlessly provide enough power to charge a power-hungry laptop, all without switching chargers or cables. Soon, the dark days of endless wall adapters and mysterious barrel jack connectors will be but a chilling memory.",
  "USB-C also tries to live by the interconnective ethos of its predecessors. If your device supports it, USB-C permits the dark arts of screen mirroring, audio sharing, lightning-fast data speeds, and wired networking.",
  "It is the one cable to rule them all … if your devices have the ports to support it.",
  "The simple promises of the USB-C – reversibility, a shared charging format, and device interconnectivity -- make it attractive for cutting that cable clutter.",
  "For now, long live the mighty USB-C and may you unify all our devices, cables, and chargers once and for all. That is, until the time comes when the next newest and greatest cable arrives.",
]