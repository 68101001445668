import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import clipping from "../resources/sep-14-24.pdf";
import usbc from "./usb-c.jpg";
const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "graduate"
}

const article: Article = {
  images: [
    {
      src: usbc,
      credit: ME,
      caption: "If your device supports it, the black USB-C cable seen here in the center can do more than just cut cable clutter by letting your devices \"negotiate\" with the charger they are connected to for the voltages they need to operate. The USB-C standard can be used to connect to external monitors, rapidly transfer data, share audio, and much more."
    }
  ],
  articleType: "column",
  author: [ ME ],
  date: new Date(2024, 8, 14),
  title: "In praise of USB-C", // "In praise of the USB-C" on the YHR site - I'm not sure if this is an AP thing, a me sucking at explaining things such that the editor got confused thing, or what
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/in-praise-of-the-usb-c/article_fb38ccd4-6e1b-11ef-9950-3b49669c2ca7.html",
  clipping,
  content
};

export default article;