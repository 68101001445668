import housesofhalloween from './houses-of-halloween';
import wombo from './wombo';
import redvinesvstwizzlers from './redvines-vs-twizzlers';
import sledding from './sledding';
import momadvice from './mom-advice';
import doctorstrange from './doctor-strange';
import pencils from './pencils';
import ignite from './ignite';
import thankful from './thankful';
import theoffice from './the-office';
import dtg from './dtg';
import yearinreview from './year-in-review';
import masks from './masks';
import rockclimbing from './rock-climbing';
import contactless from './contactless';
import cadburyeggs from './cadbury-eggs';
import autocockrocket from './autocockrocket';
import timemanagement from './time-management';
import discord from './discord';
import comics34 from './comics-34';
import mars from './mars';
import farmersmarket from './farmers-market';
import clothing from './clothing';
import staff2021 from './staff-2021';
import dadinaword from './dad-in-a-word';
import restaurants from './restaurants';
import barbenheimer from './barbenheimer';
import camera from './camera';
import onlinestart from './online-start';
import avatarwoa from './avatar-woa';
import lungcole from './lungcole';
import microsoftmentors from './microsoft-mentors';
import christmastrees from './christmas-trees';
import holidaysweets from './holiday-sweets';
import khanacademy from './khan-academy';
import staff2020 from './staff-2020';
import thor from './thor';
import climatepledge from './climate-pledge';
import wiredmice from './wired-mice';
import staff2022 from './staff-2022';
import gotg3 from './gotg3';
import halloweencandy from './halloween-candy';
import stopsigns from './stopsigns';
import fathersday2022 from './fathers-day-2022';
import amogus from './amogus';
import hsadapts from './hs-adapts';
import onlinelearning from './online-learning';
import everglades from './everglades';
import lifestraws from './lifestraws';
import staff2023 from "./staff-2023"
import buyingMusic from "./buying-music";
import bobwoodward from "./bob-woodward";
import rocketsocks from "./rocketsocks";
import fair from "./central-washington-fair";
import bynum from "./bynum";
import payne from "./payne";
import talktome from "./talk-to-me";
import lethal from "./lethal-company";
import shopping from "./shopping";
import track from "./track-photos";
import leapday from "./leap-day";
import fafsa from "./fafsa";
import mommoments from "./mothers-day";
import gorman from "./zonnie-gorman";
import valedictorians from "./top-one-percent";
import farewell from "./senior-fairwell";
import yvc from "./yvc";
import skatepark from "./skatepark";
import usbc from "./usb-c";
import traveltips from "./travel-tech-tips";
export const articles = [traveltips, usbc, skatepark, yvc, farewell, valedictorians, gorman, mommoments, fafsa, leapday, track, shopping, lethal, talktome, payne, bynum, fair, rocketsocks, bobwoodward, buyingMusic, staff2023, lifestraws, housesofhalloween, wombo, redvinesvstwizzlers, sledding, momadvice, doctorstrange, pencils, ignite, thankful, theoffice, dtg, yearinreview, masks, rockclimbing, contactless, cadburyeggs, autocockrocket, timemanagement, discord, comics34, mars, farmersmarket, clothing, staff2021, dadinaword, restaurants, barbenheimer, camera, onlinestart, avatarwoa, lungcole, microsoftmentors, christmastrees, holidaysweets, khanacademy, staff2020, thor, climatepledge, wiredmice, staff2022, gotg3, halloweencandy, stopsigns, fathersday2022, amogus, hsadapts, onlinelearning, everglades];